import { nhraLogo } from "assets/images";
import { Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useLocal } from "hooks/useLocal";

export const Trusted = () => {
  const { direction, isArabic, t } = useLocal();

  return (
    <LandingSectionContainer
      justifyContent={["center"]}
      bg="gray.100"
      padding={[20, 20, 20, 30, 60]}
      py={[40, 40, 40, 60]}
      my={["50px", "50px", "100px"]}
      height={"auto"}
      gap={[40]}
      alignItems={["center"]}
      maxWidth={["100%", "100%", "100%", "90%"]}
      borderRadius={[0, 0, 10]}
    >
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        width={["100%", "100%", "100%", "52%"]}
      >
        <Flex
          //   alignItems={isArabic ? "flex-start" : "flex-end"}
          flexDirection="column"
          width={["100%"]}
          gap={[24]}
        >
          <Text variant="heading2" fontSize={"30px"}>
            {t("trusted.title")}
          </Text>
          <Text variant="body5">{t("trusted.subTitle")}</Text>
          <Text color="black.900" variant="body4">
            {t("trusted.subHeading")}
          </Text>
          <Text variant="body3">{t("trusted.description")}</Text>
        </Flex>
      </Flex>
      <Image src={nhraLogo} width={["90%", "65%", "50%", "30%", "370px"]} />
    </LandingSectionContainer>
  );
};
