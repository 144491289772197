// Components
import {
  ChooseSpecialist,
  CompanyCooperation,
  Experts,
  InviteToFillForm,
  Growth,
  MeetAdvisor,
  Mission,
  PersonalAssistent,
  StartTherapy,
  Tariffs,
} from "../components";

import { useState } from "react";
import PreConsultationPopup from "../components/PreConsultationPopup";
import { AreasWeProvideSupport } from "components/NewDesignComponents/AreasWeProvideSupport/AreaWeProvideSupport";
import { WhyChooseNumuw } from "components/NewDesignComponents/WhyChooseNumuw/WhyChooseNumuw";
import { HowItWorks } from "components/NewDesignComponents/HowItWorks/HowItWorks";
import { Trusted } from "components/NewDesignComponents/Trusted/Trusted";
import { Testimonials } from "components/NewDesignComponents/Testimonials/Testimonials";

const MainPage = () => {
  const [isOpenPreConsultation, setIsOpenPreConsultation] = useState(false);

  const handlePreCloseConsultation = () => setIsOpenPreConsultation(false);

  return (
    <>
      <Growth setIsOpenConsultation={setIsOpenPreConsultation} />
      {/* <Mission /> */}
      <AreasWeProvideSupport />
      <WhyChooseNumuw />
      <HowItWorks />
      <Testimonials />
      <div style={{ width: "100%", background: "#F8F8F8" }}>
        <Experts />
        <CompanyCooperation />
      </div>
      <Trusted />
      <InviteToFillForm getConsultation={true} />
      {/* <MeetAdvisor /> */}
      {/* <ChooseSpecialist /> */}
      {/* <StartTherapy /> */}
      {/* <PersonalAssistent /> */}
      {/* <Tariffs /> */}
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={isOpenPreConsultation}
      />
    </>
  );
};

export default MainPage;
