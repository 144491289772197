//Styles
import Styles from "./styles.module.scss";
//Images
import doc_1 from "../../assets/images/dr_Donia.png";
import doc_2 from "../../assets/images/dr_Ansley.png";
import doc_3 from "../../assets/images/dr_Sandy.png";
//Traslation
import { useTranslation } from "react-i18next";
//Components
import SliderDoctors from "../SliderDoctors";
import { EG_flag, UK_flag, US_flag } from "assets/icons";

export const doctors = [
  {
    id: 1,
    name: "experts.DoniaFahim.name",
    speciality: "experts.DoniaFahim.speciality",
    country: "experts.DoniaFahim.country",
    Flag: UK_flag,
    languages: [
      "experts.DoniaFahim.languages.1",
      "experts.DoniaFahim.languages.2",
    ],
    skills: ["experts.DoniaFahim.skills.1", "experts.DoniaFahim.skills.2"],
    experience: "28+",
    exp_text: "experts.DoniaFahim.experience",
    photo: doc_1,
  },
  {
    id: 2,
    name: "experts.AnsleyHodges.name",
    speciality: "experts.AnsleyHodges.speciality",
    country: "experts.AnsleyHodges.country",
    Flag: US_flag,
    languages: ["experts.AnsleyHodges.languages.1"],
    skills: ["experts.AnsleyHodges.skills.1", "experts.AnsleyHodges.skills.2"],
    experience: "22+",
    exp_text: "experts.AnsleyHodges.experience",
    photo: doc_2,
  },
  {
    id: 3,
    name: "experts.SandyTahan.name",
    speciality: "experts.SandyTahan.speciality",
    country: "experts.SandyTahan.country",
    Flag: EG_flag,
    languages: [
      "experts.SandyTahan.languages.1",
      "experts.SandyTahan.languages.2",
    ],
    skills: ["experts.SandyTahan.skills.1"],
    experience: "20+",
    exp_text: "experts.SandyTahan.experience",
    photo: doc_3,
  },
];

const Experts = () => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";
  return (
    <section className={Styles.wrapper} id={"experts"}>
      <h1 className={Styles.title} dir={textDirection}>
        {t("expertsPage.expertsTitle")}
      </h1>
      <p className={Styles.description} dir={textDirection}>
        {t("expertsPage.expertsDescr")}
      </p>

      <div className={Styles.experts}>
        {doctors.map(({ Flag, ...doctor }) => {
          return (
            <div key={doctor.id} className={Styles.card_wrapper}>
              <img src={doctor.photo} alt="doctor" />

              <div className={Styles.card_descr} dir={textDirection}>
                <p className={Styles.name}>{t(doctor.name)}</p>
                <p className={Styles.speciality}>{t(doctor.speciality)}</p>

                <div className={Styles.country}>
                  <div className={Styles.square}>
                    {/* <span className={doctor.flag}></span> */}
                    <Flag />
                  </div>
                  <span>{t(doctor.country)}</span>
                </div>

                <div className={Styles.languages}>
                  <div className={Styles.square}>
                    <span className={Styles.lang}></span>
                  </div>

                  {doctor.languages.map((language, index) => (
                    <span key={index}>{t(language)}</span>
                  ))}
                </div>

                <div className={Styles.skills}>
                  <div className={Styles.square}>
                    <span className={Styles.certificate}></span>
                  </div>
                  {textDirection === "ltr" && (
                    <>
                      {doctor.skills.map((skill, index) => (
                        <span key={index}>{t(skill)}</span>
                      ))}
                    </>
                  )}

                  {textDirection === "rtl" && (
                    <div className={Styles.ar_skills}>
                      {doctor.skills.map((skill, index) => (
                        <span key={index}>{t(skill)}</span>
                      ))}
                    </div>
                  )}
                </div>

                <div className={Styles.experience}>
                  <span>{doctor.experience}</span>
                  <span>{t(doctor.exp_text)}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={Styles.wrapper_slider}>
        <SliderDoctors />
      </div>
    </section>
  );
};

export default Experts;
