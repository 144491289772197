import styled from "styled-components";
import { fontSize, fontWeight, textAlign, variant } from "styled-system";
import type { TextProps } from "./types";
import { commonComposes } from "../common/commonComposes";
import { theme } from "theme/theme";

export const Text = styled("p")<TextProps>(
  {
    fontFamily: "Poppins, sans-serif",
  },
  variant({
    variants: {
      body1: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins, sans-serif",
      },
      body2: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins, sans-serif",
      },
      body3: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins, sans-serif",
      },
      body4: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins, sans-serif",
      },
      body5: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins, sans-serif",
      },
      body6: {
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        color: "textGray",
      },
      heading1: {
        color: theme.colors.black[900],
        fontSize: ["25px", "25px", "30px", "30px", "36px", "36px"],
        fontWeight: 600,
        lineHeight: ["auto", "40px", "40px", "40px", "36px", "40px", "46px"],
        maxWidth: "580px",
        width: "100%",
      },
      heading2: {
        color: theme.colors.black[900],
        fontSize: ["20px", "20px", "25px", "25px", "26px", "28px", "30px"],
        fontWeight: 600,
        lineHeight: ["auto", "40px", "40px", "40px", "36px", "40px", "46px"],
        maxWidth: "580px",
        width: "100%",
      },
      charcoalBody: {
        color: theme.colors.charcoal,
        fontSize: ["16px", "16px", "16px", "16px", "16px", "20px"],
        letterSpacing: "-.02em",
        lineHeight: "30px",
        maxWidth: "610px",
        width: "100%",
      },
      numberHolder: {
        color: "white",
        backgroundColor: "typePurpure.200",
        fontWeight: 600,
        fontSize: "14px",
        height: "25px",
        width: "25px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: 0,
      },
    },
  }),
  commonComposes([textAlign, fontWeight, fontSize])
);
