import { howItWorks } from "assets/images";
import { Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useLocal } from "hooks/useLocal";
import { useMemo } from "react";
import { HowItWorkSteps } from "./HowItWorkSteps";

export const HowItWorks = () => {
  const { direction, isArabic, t } = useLocal();
  const steps = useMemo(
    () => t("howItWorks.steps", { returnObjects: true }),
    [isArabic]
  ) as Array<{ title: string; description: string }>;
  return (
    <LandingSectionContainer
      justifyContent={["space-between"]}
      flexDirection={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
      mb={["50px", "50px", "100px"]}
      height={"auto"}
      gap={[40]}
    >
      <Image src={howItWorks} width={["100%", "100%", "80%", "46%"]} />
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        width={["100%", "100%", "100%", "52%"]}
      >
        <Flex
          //   alignItems={isArabic ? "flex-start" : "flex-end"}
          flexDirection="column"
          width={["100%"]}
        >
          <Text mb={[10]} variant="heading1">
            {t("howItWorks.title")}
          </Text>
          <HowItWorkSteps startNow={t("buttons.startNow")} steps={steps} />
        </Flex>
      </Flex>
    </LandingSectionContainer>
  );
};
