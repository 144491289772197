//Core
import React, { useRef } from "react";
//Styles
import Styles from "./styles.module.scss";
//SLick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Mui Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { doctors } from "../Experts";

//Traslation
import { useTranslation } from "react-i18next";

const SliderDoctors = () => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    className: "center",
    initialSlide: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "240px",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          centerPadding: "180px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerPadding: "160px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 3,
          centerPadding: "0px",
        },
      },
    ],
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
  };

  return (
    <>
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {doctors.map(({ Flag, ...doctor }) => {
          return (
            <div key={doctor.id} className={Styles.card_wrapper}>
              <img src={doctor.photo} alt="doctor" />

              <div className={Styles.card_descr} dir={textDirection}>
                <p className={Styles.name}>{t(doctor.name)}</p>
                <p className={Styles.speciality}>{t(doctor.speciality)}</p>

                <div className={Styles.country}>
                  <div className={Styles.square}>
                    <Flag />
                  </div>
                  <span>{t(doctor.country)}</span>
                </div>

                <div className={Styles.languages}>
                  <div className={Styles.square}>
                    <span className={Styles.lang}></span>
                  </div>

                  {doctor.languages.map((language, index) => (
                    <span key={index}>{t(language)}</span>
                  ))}
                </div>

                <div className={Styles.skills}>
                  <div className={Styles.square}>
                    <span className={Styles.certificate}></span>
                  </div>
                  {textDirection === "ltr" && (
                    <>
                      {doctor.skills.map((skill, index) => (
                        <span key={index}>{t(skill)}</span>
                      ))}
                    </>
                  )}

                  {textDirection === "rtl" && (
                    <div className={Styles.ar_skills}>
                      {doctor.skills.map((skill, index) => (
                        <span key={index}>{t(skill)}</span>
                      ))}
                    </div>
                  )}
                </div>

                <div className={Styles.experience}>
                  <span>{doctor.experience}</span>
                  <span>{t(doctor.exp_text)}</span>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className={Styles.arrow_wrapper}>
        <div className={Styles.arrow_content}>
          <div className={Styles.arrow_block} onClick={previous}>
            <ArrowBackIcon className={Styles.arrow_icon} />
          </div>

          <div className={Styles.arrow_block} onClick={next}>
            <ArrowForwardIcon className={Styles.arrow_icon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderDoctors;
