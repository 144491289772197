//Styles
import { Button } from "components/Core";
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TherapistSolution = () => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  return (
    <section className={Styles.wrapper}>
      <h1 className={Styles.title} dir={textDirection}>
        {t("solutionsForTherapist.solutionForTherapist")}
      </h1>
      <p className={Styles.description} dir={textDirection}>
        {t("solutionsForTherapist.solutionForTherapistDescr")}
      </p>
      <Link to="/join-us">
        <Button mt={[20]} variant="smallCTA">
          {t("buttons.joinNetwork")}
        </Button>
      </Link>
      <div className={Styles.content_block}>
        <div dir={textDirection}>
          <h1 className={`${Styles.title} ${Styles.content_block_title}`}>
            {t("solutionsForTherapist.flexibleWork")}
          </h1>
          <p
            className={`${Styles.description} ${Styles.content_block_description}`}
          >
            <span className={Styles.content_span}>
              {t("solutionsForTherapist.flexibleWorkDescr")}
            </span>
          </p>
        </div>

        <div className={Styles.image} />
      </div>
    </section>
  );
};

export default TherapistSolution;
