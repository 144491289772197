import { families } from "assets/images";
import { Box, Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { WhyChooseDetail } from "./WhyChooseDetail";
import { useLocal } from "hooks/useLocal";
import { useMemo } from "react";

export const WhyChooseNumuw = () => {
  const { direction, isArabic, t } = useLocal();
  const reasons = useMemo(
    () => t("whyChooseNumuw.reasons", { returnObjects: true }),
    [isArabic]
  ) as Array<{ title: string; description: string }>;
  return (
    <LandingSectionContainer mb={[50, 50, 50, "100px"]} height={"auto"}>
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        gap={[30]}
        width={["100%", "100%", "100%", "60%"]}
      >
        <Flex
          alignItems={isArabic ? "flex-end" : "flex-start"}
          flexDirection="column"
          width={["100%"]}
        >
          <Text mb={[30]} variant="heading1">
            {t("whyChooseNumuw.title")}
          </Text>
          <Text
            lineHeight={"26px"}
            fontSize={18}
            width={["100%", "100%", "100%", "82%"]}
          >
            {t("whyChooseNumuw.subTitle")}
          </Text>
        </Flex>
        <Flex justifyContent={isArabic ? "flex-end" : "flex-start"}>
          <WhyChooseDetail reasons={reasons} />
        </Flex>
      </Flex>
      <Box
        flexGrow={1}
        borderRadius={15}
        width={["100%", "100%", "100%", "50%"]}
        bg="black.100"
        mt={[10, 10, 10, 0]}
      >
        <Image src={families} width={["100%"]} />
      </Box>
    </LandingSectionContainer>
  );
};
