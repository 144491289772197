import { styled } from "@mui/material/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Text } from "components/Core";
import { useState } from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingLeft: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMore sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  border: "none",
  paddingLeft: 0,
  paddingRight: 0,
  justifyContent: "space-between",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "none",
}));

type Props = {
  reasons: { title: string; description: string }[];
};

export function WhyChooseDetail({ reasons }: Props) {
  const [expanded, setExpanded] = useState<string | false>("panel0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box width={["100%", "100%", "100%", "80%"]}>
      {reasons?.map((item, index) => (
        <Accordion
          key={item.title}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id={`panel${index}d-header`}
          >
            <Text variant="body1">{item.title}</Text>
          </AccordionSummary>
          <AccordionDetails>
            <Text variant="body3">{item.description}</Text>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
