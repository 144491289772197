import type { ReactNode } from "react";
import { type BoxProps, Container } from "components/Core";

interface Props extends BoxProps {
  children: ReactNode;
}
export const LandingSectionContainer = ({ children, ...rest }: Props) => {
  return (
    <Container
      height={["auto", "auto", "auto", "auto", "520px", "600px"]}
      maxWidth={["1440px"]}
      width={["100%"]}
      margin="0 auto"
      justifyContent={["center", "center", "center", "space-around"]}
      id={"about"}
      padding={["20px", "20px", "40px", "40px", 40, "0 30px 0 105px"]}
      flexDirection={["column", "column", "column", "row"]}
      {...rest}
    >
      {children}
    </Container>
  );
};
