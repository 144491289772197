import { CenterRow, Flex, Text } from "components/Core";
import { useState } from "react";
import {
  NumberedAccordion,
  NumberedAccordionDetails,
  NumberedAccordionSummary,
} from "components/NumberedAccordions/NumberedAccordions";

type Props = {
  steps: { label: string; description: string }[];
};

export function ConsultationSteps({ steps }: Props) {
  const [expanded, setExpanded] = useState<string | false>("panel0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Flex
      overflowY="auto"
      overflowX="hidden"
      flexDirection={["column"]}
      width={["100%"]}
      // height={["100px"]}
      px={10}
    >
      {steps?.map((step, index) => (
        <NumberedAccordion
          key={step?.label}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <NumberedAccordionSummary
            aria-controls="panel1d-content"
            id={`panel${index}d-header`}
          >
            <CenterRow gap="7px">
              <Text variant="numberHolder">{index + 1}</Text>
              <Text variant="body1">{step?.label}</Text>
            </CenterRow>
          </NumberedAccordionSummary>
          <NumberedAccordionDetails>
            <Text variant="body3">{step?.description}</Text>
          </NumberedAccordionDetails>
        </NumberedAccordion>
      ))}
    </Flex>
  );
}
