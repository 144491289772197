//Core
import React, { useEffect, useRef, useState } from "react";
//Router
import { Link, useSearchParams, useLocation } from "react-router-dom";
//Styles
import Styles from "./styles.module.scss";
//Images
import logo from "../../assets/images/logo.svg";
//Translation
import i18next from "i18next";
import { useTranslation } from "react-i18next";
//Components
import PreConsultationPopup from "../PreConsultationPopup";
import { Button } from "components/Core";

const Header = ({ header, consultButton }) => {
  const { t, i18n } = useTranslation(["common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const headerMobileRef = useRef(null);

  const [isOpenPreConsultation, setIsOpenPreConsultation] = useState(false);
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);

  const handleShowHeaderMenu = () => setIsOpenHeaderMenu(!isOpenHeaderMenu);
  const handlePreOpenConsultation = () => setIsOpenPreConsultation(true);
  const handlePreCloseConsultation = () => setIsOpenPreConsultation(false);

  useEffect(() => {
    if (searchParams.get("lang")) {
      i18next.changeLanguage(searchParams.get("lang"));
    } else if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("ar");
    }
    setSearchParams({ lang: localStorage.getItem("i18nextLng") });
  }, []);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setSearchParams({ lang: lang });
  };

  const currentLang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headerMobileRef.current &&
        !headerMobileRef.current.contains(event.target)
      ) {
        setIsOpenHeaderMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.header_logo}>
          <a href={"/"}>
            <img width={145} src={logo} alt={"logo"} />
          </a>
        </div>

        {header && (
          <div
            className={`${Styles.header_interaction} ${
              pathname === "/join-us" && Styles.only_logo_block
            }`}
          >
            <div
              className={`${Styles.header_languageBlock} ${
                pathname === "/join-our-network" && Styles.language_visible
              }`}
            >
              <button
                className={
                  currentLang === "en"
                    ? Styles.lang_select_active
                    : Styles.lang_select
                }
                onClick={() => handleLanguageChange("en")}
              >
                English
              </button>
              |
              <button
                className={
                  currentLang === "ar"
                    ? Styles.lang_select_active
                    : Styles.lang_select
                }
                onClick={() => handleLanguageChange("ar")}
              >
                العربية
              </button>
            </div>
            {consultButton && (
              <Link to="/join-our-network" className={Styles.therapist_btn}>
                {t("buttons.joinNetwork")}
              </Link>
            )}
            {consultButton && (
              <Button
                variant="smallCTA"
                ml={[42]}
                onClick={handlePreOpenConsultation}
                // className={Styles.consult_btn}
              >
                {t("buttons.getStartedToday")}
              </Button>
            )}
          </div>
        )}
        {pathname !== "/join-us" && (
          <>
            <div
              ref={headerMobileRef}
              onClick={handleShowHeaderMenu}
              class={Styles.menu_bar}
            >
              {Array.from({ length: 4 }).map((_) => (
                <div class={Styles.menu_line}></div>
              ))}
            </div>
            {isOpenHeaderMenu && (
              <div className={Styles.mobile_header_menu}>
                <div className={Styles.mobile_language_block}>
                  <button
                    className={
                      currentLang === "en"
                        ? Styles.lang_select_active
                        : Styles.lang_select
                    }
                    onClick={() => handleLanguageChange("en")}
                  >
                    English
                  </button>
                  |
                  <button
                    className={
                      currentLang === "ar"
                        ? Styles.lang_select_active
                        : Styles.lang_select
                    }
                    onClick={() => handleLanguageChange("ar")}
                  >
                    العربية
                  </button>
                </div>
                <Link
                  to="/join-our-network"
                  className={Styles.mobile_therapist}
                >
                  {t("buttons.becomeTherapist")}
                </Link>
                <Button
                  my={3}
                  mb={4}
                  variant="smallCTA"
                  onClick={handlePreOpenConsultation}
                  // className={Styles.mobile_consultation}
                >
                  {t("buttons.getStartedToday")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={isOpenPreConsultation}
      />
    </header>
  );
};

export default Header;
