import { styled } from "@mui/material/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  type AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import type { ReactNode } from "react";

interface AccordionSummaryType extends AccordionSummaryProps {
  expandIcon?: ReactNode;
}

export const NumberedAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingLeft: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export const NumberedAccordionSummary = styled(
  (props: AccordionSummaryType) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMore sx={{ fontSize: "1.5rem" }} />}
      {...props}
    />
  )
)(({ theme }) => ({
  backgroundColor: "white",
  border: "none",
  paddingLeft: 0,
  paddingRight: 0,
  // flexDirection: "row-reverse",
  justifyContent: "space-between",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const NumberedAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "none",
  })
);
