import type { DirectionType } from "components/Core/common/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useLocal = () => {
  const { t, i18n } = useTranslation(["common"]);
  const isArabic = useMemo(() => i18n.language === "ar", [i18n.language]);
  const direction = useMemo(
    () => (isArabic ? "rtl" : "ltr"),
    [isArabic]
  ) as DirectionType;
  return {
    isArabic,
    direction,
    t,
  };
};
