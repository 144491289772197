// eslint-disable-next-line no-undef
export const apiPath = process.env.REACT_APP_API_PATH;

export const QUESTIONNAIRE_LINK = process.env.REACT_APP_QUESTIONNAIRE_LINK;

export const GOOGLE_TAG_MANAGER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const areasWeProvideSupport = [
  "Aggression & Tantrums",
  "Anxiety and Depression",
  "Attention, Focus, & ADHD",
  "Autism Spectrum Disorders",
  "Behavioral Issues",
  "Communication & Speech Delays",
  "Developmental Delays",
  "Emotional Regulation",
  "Parenting Guidance & Support",
  "Sleep & Routine Challenges",
  "Social Interaction Difficulties",
];

export const testimonials = [
  {
    id: 1,
    name: "Numuw Parent",
    text: "Numuw has been a game-changer for our family! Audio and video testimonials are another popular way to tell customer stories through vivid audio and video messages. Unlike quote testimonials that tell everything with emotionless words and tones",
    // image: "https://shorturl.at/GtbHe",
  },
  {
    id: 2,
    name: "Numuw Member",
    text: "The specialists at Numuw are truly exceptional. Audio and video testimonials are another popular way to tell customer stories through vivid audio and video messages. Unlike quote testimonials that tell everything with emotionless words and tones",
    // image: "https://shorturl.at/HUF76",
  },
  {
    id: 3,
    name: "Numue Caregiver",
    text: "I highly recommend Numuw to all parents. Audio and video testimonials are another popular way to tell customer stories through vivid audio and video messages. Unlike quote testimonials that tell everything with emotionless words and tones",
    // image: "https://shorturl.at/xA0IX",
  },
];

export const appNumuwGetStartedUrl =
  "https://app.numuw.com/signup/client?lng=en";
