//Styles
import { heroImage } from "assets/images";
import { Box, Button, Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useLocal } from "hooks/useLocal";

const Growth = ({ setIsOpenConsultation }) => {
  const { direction, isArabic, t } = useLocal();

  const handleOpenConsultation = () => setIsOpenConsultation(true);

  return (
    <LandingSectionContainer gap={[16]} mb={[50]}>
      <Flex
        flexDirection={["column"]}
        width={["100%", "100%", "100%", "48%"]}
        direction={direction}
        mr={[0, 0, 0, 40]}
        gap={[16, 16, 16, 36]}
      >
        <Text
          textAlign={[
            "center",
            "center",
            "center",
            isArabic ? "right" : "left",
          ]}
          variant="heading1"
        >
          {t("hero.title")}
        </Text>
        <Text variant="charcoalBody">{t("hero.heroDescription")}</Text>

        <Button
          variant="getStarted"
          // my={[36, 36, 36, 15, 15, , 36]}
          width={["100%", "100%", "100%", "max-content"]}
          button
          onClick={handleOpenConsultation}
        >
          {t("buttons.getStartedToday")}
        </Button>
      </Flex>
      <Box
        borderRadius={15}
        width={["100%", "100%", "100%", "52%"]}
        bg="black.100"
      >
        <Image width="100%" src={heroImage} />
      </Box>
    </LandingSectionContainer>
  );
};

export default Growth;
