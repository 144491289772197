import { system } from "styled-system";

const scaleConfig = {
  scale: {
    property: "scale" as const,
    transform: (value: number | string) => `${value}`,
  },
};

export const scale = system(scaleConfig);
