import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";

import "./styles/main.scss";

import { clarity } from "react-microsoft-clarity";
import App from "./App";
//Translation
import "./i18n";
import { GoogleTagManager } from "./components/TagManagers/GoogleTagManager";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import { theme } from "theme/theme";
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "theme/muiThem";

clarity.init(process.env.REACT_APP_CLARITY_ID);
clarity.consent();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StyledComponentThemeProvider theme={theme}>
      <ThemeProvider theme={muiTheme}>
        <Router>
          <App />
          <GoogleTagManager />
        </Router>
      </ThemeProvider>
    </StyledComponentThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
